<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <b-row>
      <b-col>
        <h1 class="page-header">Users <small>all listings.</small></h1>
      </b-col>
    </b-row>

    <!-- search -->
    <display-results :query="query" :index-name="usersIndex" v-slot:default="{ items }">
      <b-table :fields="fields" :items="items" show-empty stacked="sm" bordered hover>
        <template v-slot:cell(name)="row">
          <b-row align-v="center">
            <b-col cols="auto">
              <b-avatar :src="row.item.avatar_url" />
            </b-col>

            <b-col>{{ row.item.full_name }}</b-col>
          </b-row>
        </template>

        <template v-slot:cell(verified)="row">
          <b-badge :variant="row.item.email_verified_at ? 'primary' : 'warning'">
            {{ row.item.email_verified_at ? 'Verified' : 'Unverified' }}
          </b-badge>
        </template>

        <template v-slot:cell(last_login_date)="row">
          <b-badge>
            {{ row.item.last_login_date ? formatDate(row.item.last_login_date) : 'Never Logged In' }}
          </b-badge>
        </template>

        <template v-slot:cell(options)="{ item }">
          <b-dropdown text="More" variant="light" size="sm" right>
            <b-dropdown-item :to="{ name: 'users.profile', params: { id: item.id } }">
              Profile
            </b-dropdown-item>

            <template v-if="!item.email_verified_at">
              <b-dropdown-item @click="verifyUser(item)">
                Verify User
              </b-dropdown-item>
            </template>

            <template v-if="item.email_verified_at">
              <b-dropdown-item @click="downloadGDPRData(item)">
                Download GDPR data
              </b-dropdown-item>
              <b-dropdown-divider />

              <!-- User Impersonate -->
              <template>
                <b-dropdown-item @click="impersonate(item)">
                  Impersonate
                </b-dropdown-item>

                <b-modal :id="`not-impersonate-${item.id}`" title="Impersonation Forbidden" ok-only>
                  This user is an administrator, as such impersonation is strictly forbidden.
                </b-modal>
              </template>
              <!-- End User Impersonate -->

              <b-dropdown-divider />

              <!-- User permissions -->
              <template>
                <b-dropdown-item v-b-modal="`user-permissions-${item.id}`">
                  Permissions
                </b-dropdown-item>

                <b-modal
                  :id="`user-permissions-${item.id}`"
                  :title="`Update Permissions: ${item.full_name}`"
                  hide-footer
                >
                  <b-list-group>
                    <b-list-group-item v-for="(role, i) in roles" :key="i">
                      <b-row align-v="center" align-h="between">
                        <b-col cols="auto">
                          <strong>{{ role.name }}</strong>
                        </b-col>
                        <b-col cols="auto">
                          <b-button
                            :variant="hasRole(item, role) ? 'secondary' : 'primary'"
                            @click="syncRole(item, role)"
                          >
                            {{ hasRole(item, role) ? 'Disable' : 'Enable' }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-modal>
              </template>
              <!-- End User permissions -->

              <b-dropdown-divider />
              <b-dropdown-item @click="showPasswordModal(item)">
                Reset password
              </b-dropdown-item>
              <b-dropdown-item v-b-modal="`delete-user-${item.id}`">
                Delete User
              </b-dropdown-item>
            </template>

            <b-modal :id="`delete-user-${item.id}`" title="Delete User" @ok="deleteUser(item)">
              <p class="my-4">Are you sure you want to delete {{ item.full_name }}?</p>
            </b-modal>

            <b-modal :id="`reset-password-${item.id}`" title="Reset Password" hide-footer>
              <validation-observer v-slot="{ invalid }">
                <form @submit.prevent="resetPassword(item)">
                  <validation-provider name="Email Address" v-slot="context" rules="required|email">
                    <b-form-group label="Enter email">
                      <b-form-input
                        v-model="resetEmail"
                        placeholder="e.g user@example.com"
                        :state="getValidationState(context)"
                        readonly
                        trim
                      />
                    </b-form-group>
                  </validation-provider>

                  <b-row align-h="end">
                    <b-col cols="auto">
                      <b-button variant="primary" type="submit" :disabled="invalid">
                        Reset Password
                      </b-button>
                    </b-col>
                  </b-row>
                </form>
              </validation-observer>
            </b-modal>
          </b-dropdown>
        </template>
      </b-table>
    </display-results>
  </div>
</template>

<script>
import { users, passwords, roles as rolesApi } from '@/api'
import { formatDate, getValidationState } from '../page-helpers'
import DisplayResults from '../../components/DisplayResults.vue'

export default {
  name: 'UsersPage',
  components: { DisplayResults },
  data() {
    return {
      query: this.$route.query.query,
      usersIndex: process.env.VUE_APP_ALGOLIA_USERS_INDEX,
      resetEmail: '',
      roles: [],
      formatDate,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'name', sortable: false },
        { key: 'email', sortable: false },
        { key: 'verified', sortable: false },
        {
          key: 'order_count',
          sortable: false,
          formatter: value => value ?? 0,
        },
        { key: 'last_login_date', sortable: false, formatter: formatDate },
        { key: 'options', sortable: false, label: '' },
      ]
    },
  },
  async mounted() {
    this.roles = (await rolesApi.all()).data.filter(role => role.name !== 'Supplier')
  },
  methods: {
    getValidationState,
    async downloadGDPRData(user) {
      await users.dataDownload(user.id)
      this.$bvToast.toast(`An email containing all the data will be sent shortly.`, {
        title: 'GDPR Data',
        variant: 'primary',
      })
    },
    showPasswordModal(user) {
      this.resetEmail = user.email

      this.$bvModal.show(`reset-password-${user.id}`)
    },
    async deleteUser(user) {
      await users.delete(user.id)
      this.$bvToast.toast(`User "${user.full_name}" will be deleted shortly.`, {
        title: 'User deleted',
        variant: 'primary',
      })
    },
    async resetPassword(user) {
      await passwords.reset(this.resetEmail)

      this.$bvModal.hide(`reset-password-${user.id}`)
    },
    async impersonate(user) {
      try {
        await users.impersonate(user.id)

        window.open(process.env.VUE_APP_APP_URL, '_blank').focus()
      } catch (error) {
        if (error.code === 'request.forbidden') {
          this.$bvModal.show(`not-impersonate-${user.id}`)
        }
      }
    },
    hasRole(user, role) {
      return user.roles?.includes(role.name)
    },
    async syncRole(user, role) {
      await users.permissions(user.id).sync({ data: [role.id] })
      this.$bvModal.hide(`user-permissions-${user.id}`)
      this.$bvToast.toast(`${user.full_name} is now a "${role.name}".`, {
        title: 'Role synced',
        variant: 'primary',
      })
    },
    async verifyUser(user) {
      await users.verify(user.id)
      this.$bvToast.toast(`"${user.full_name}" has been successfully verified.`, {
        title: 'User Verified',
        variant: 'primary',
      })

      user.email_verified_at = new Date()
    },
  },
}
</script>
